import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import logo from '../images/3dmeetLogo.png'
import '../styles/global.css'

const PAGE_CLASSES = 'center center-text'
const LOGO_CLASSES = 'logo'
const LOGO_ALT = '3Dmeet.com'
const TEACHER_ROUTE = '/teacher'

const pageStyles = {
  padding: 96,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
}

const paragraphStyles = {
  marginBottom: 48,
}

// markup
const IndexPage = (): JSX.Element => {
  return (
    <main style={pageStyles} className={PAGE_CLASSES}>
      <Helmet>
        <title>Test1</title>
      </Helmet>
      <img src={logo} className={LOGO_CLASSES} alt={LOGO_ALT} />
      <h1 style={headingStyles}>
        Welcome to 3Dmeet&apos;s LMS integration site!
      </h1>
      <p style={paragraphStyles}>
        Email us at support@fluentworlds.com to get your classes started!{' '}
        <span role="img" aria-label="Sunglasses smiley emoji">
          😎
        </span>
      </p>
      <Link to={TEACHER_ROUTE}>
        Click here if you already have a teacher&apos;s account
      </Link>
    </main>
  )
}

export default IndexPage
